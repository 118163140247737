import React, { useEffect } from 'react';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { PersonalDetailsFooter, NavigationButton } from './styles';
import Form, { Input } from '../styles';
import API from '../../../agents/API';

import { set as setLocalStorage } from '../../../utils/localStorage';
import { get as getSessionStorage, set as setSessionStorage, remove as removeSessionStorage } from '../../../utils/sessionStorage';
import isBrowser from '../../../utils/isBrowser';
import theme from '../../../styles/theme';

const LIST_IDS = [{ id: 'falls_festival', name: 'Falls Festival', value: 6 }, { id: 'presales', name: 'Tour & Tour Presales', value: 4 }];

export const PersonalDetails: React.FunctionComponent<any> = ({ nextStep, setState }) => {
  if (!isBrowser()) {
    return null;
  }

  const token = getSessionStorage('token');
  const user = getSessionStorage('user');

  // Check if we have a token in session on first render
  useEffect(() => {
    if (!token) {
      window.location.replace('/');
    }
  }, []);

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        dob: '',
        postcode: null,
        subscribe: false,
        lists: [],
        ...user,
      }}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          const { data: updatedContact } = await API.updateContact({ contact: values, token });

          removeSessionStorage('token');
          setLocalStorage('token', token);
          setSessionStorage('user', JSON.stringify(updatedContact));
          setState(values);
          nextStep();
        } catch (e) {
          const status = e.response ? e.response.status : null;
          // Email already exists
          if (status === 409) {
            setFieldError('email', 'User with that email already exists.');
          }

          setSubmitting(false);
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string()
          .email('!')
          .required('!'),
        dob: Yup.string()
          .transform((value) => (/^(?:\d{2}\/){2}\d{4}$/.test(value) ? value : false))
          .required(),
        postcode: Yup.number()
          .transform((value) => (value.toString().length === 4 ? value : false))
          .required(),
        subscribe: Yup.boolean().oneOf([true], 'Must Agree to Terms'),
      })}
    >
      {({ handleSubmit, handleChange, handleBlur, touched, values, errors, isSubmitting, dirty }) => {
        const inputProps = { onChange: handleChange, onBlur: handleBlur };

        return (
          <Form onSubmit={handleSubmit} loading={isSubmitting}>
            <NavigationButton onClick={() => window.history.back()} align="left">
              Back
            </NavigationButton>
            <Form.Header>
              <Form.Title>Enter your personal details</Form.Title>
              <Form.Subtitle>Signup to hear about presales, news and important festival announcements.</Form.Subtitle>
            </Form.Header>

            <Input.Group>
              <Input.Text id="name" placeholder="Full Name*" value={values.name} error={touched.name && errors.name} {...inputProps} />
              <Input.Text id="email" placeholder="Email*" value={values.email} error={touched.email && errors.email} {...inputProps} />
              {errors.email && errors.email !== '!' ? (
                <div style={{ marginTop: -16, marginBottom: 16, color: theme.colors.pink }}>{errors.email}</div>
              ) : null}

              <Input.Text
                id="dob"
                placeholder="Date of birth* (DD/MM/YYYY)"
                value={values.dob}
                error={touched.dob && errors.dob}
                {...inputProps}
              />
              <Input.Text
                id="postcode"
                placeholder="Postcode*"
                value={values.postcode}
                error={touched.postcode && errors.postcode}
                {...inputProps}
              />
            </Input.Group>

            <div style={{ marginTop: 32 }}>
              <h3 className="h7 text-green" style={{ marginBottom: 16 }}>
                Hear more about your favourite artists from Splendour and our partners.
              </h3>

              <FieldArray
                name="lists"
                render={({ push, remove }) => (
                  <>
                    {LIST_IDS.map(({ id, name, value }) => (
                      <Input.Group style={{ marginBottom: 16 }}>
                        <Input.Checkbox
                          id={id}
                          name="lists"
                          value={value}
                          checked={values.lists.includes(value)}
                          onChange={(e) => (e.target.checked ? push(value) : remove(values.lists.indexOf(value)))}
                          onBlur={handleBlur}
                        />
                        <Input.Label htmlFor={id}>{name}</Input.Label>
                      </Input.Group>
                    ))}
                  </>
                )}
              />
            </div>

            <PersonalDetailsFooter>
              <Input.Group>
                <div
                  className={`p6 text-uppercase font-800 ${touched.subscribe && errors.subscribe ? 'text-pink' : 'text-white'}`}
                  style={{ flex: '0 0 100%', marginBottom: 16 }}
                >
                  *Required Field
                </div>
                <Input.Checkbox id="subscribe" checked={values.subscribe} {...inputProps} />
                <Input.Label htmlFor="subscribe">By ticking this you agree to be contacted by Splendour in the Grass</Input.Label>
              </Input.Group>

              <Input.Button type="submit">Next</Input.Button>
            </PersonalDetailsFooter>
          </Form>
        );
      }}
    </Formik>
  );
};
