import React from 'react';

interface State {
  formState: any;
  currentStep: number;
}

interface Props {
  steps: any[];
}

class MasterForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      formState: {},
    };
  }

  nextStep = () => {
    if (this.state.currentStep < this.props.steps.length) {
      this.setState({ currentStep: this.state.currentStep + 1 });
    }
  };

  prevStep = () => {
    if (this.state.currentStep > 0) {
      this.setState({ currentStep: this.state.currentStep - 1 });
    }
  };

  setFormState = (newState: any) => {
    this.setState({ formState: newState });
  };

  render() {
    if (this.props.steps) {
      const Component = this.props.steps[this.state.currentStep];

      return (
        <Component
          nextStep={() => this.nextStep()}
          prevStep={() => this.prevStep()}
          getState={() => this.state.formState}
          setState={(e) => this.setFormState(e)}
        />
      );
    }

    return null;
  }
}

export default MasterForm;
