import styled from 'styled-components';
import Form, { Input } from '../styles';
import Theme from '../../../types/Theme';

interface BubbleProps extends Theme {
  gradient: keyof Theme['theme']['linearGradient'];
  image: string;
  size: 'lg' | 'md' | 'sm';
}

export const BubblesContainer = styled.div`
  width: 480px;
  height: 338px;
  margin: auto;
  position: relative;
  margin-bottom: 24px;
  display: flex;

  @media (max-width: 530px) {
    width: 372px;
  }
`;

export const Bubble = styled.div(
  ({ theme: { linearGradient }, size, image, gradient }: BubbleProps) => `
    position: absolute;
    background: ${linearGradient[gradient]}, url(${image}) 0px; 
    background-blend-mode: multiply;
    background-size: cover;
    border-radius: 100%;
    display: block;
    overflow: hidden;
    width: ${size === 'lg' ? '192' : size === 'md' ? '120' : '72'}px;

    &::after {
      content: '';
      display: block;
      padding-top: 100%;
    }
  `,
);

export const PlaylistContainer = styled.div(
  ({ theme }: Theme) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    h2 {
      line-height: 1;
      margin-bottom: 32px;
    }

    h3 {
      line-height: 1;
      margin-bottom: 48px;
    }

    h5 {
      line-height: 1.5;
      letter-spacing: 0.4px;
      margin-bottom: 24px;
    }

    .spotify-embed {
      opacity: 0;
      transition: opacity .2s ease-in-out;

      &.visible {
        opacity: 1;
      }
    }

    & > div {
      flex: 1;

      &:nth-of-type(1) {
        max-width: 624px;
        padding-right: 24px;
      }

      &:nth-of-type(2) {
        iframe {
          width: 100%;
          max-width: 352px;
          display: block;
          margin: auto;

          ${theme.tabletView} {
            margin-right: 0;
          }

          ${theme.mobileView} {
            margin: auto;
          }
        }
      }
    }

    ${theme.mobileView} {
      text-align: center;
      padding-bottom: 128px;

      & > div {
        flex: 1 100%;
        max-width: 100%;
      }
    }
  `,
);

export const NavigationButton = styled(Input.Button).attrs({ type: 'button' })(
  ({ align }: { align: 'left' | 'right' }) => `
      display: block;
      margin-${align === 'left' ? 'right' : 'left'}: auto;
      font-size: 16px;
      margin-bottom: 16px;

      &:hover {
        text-decoration: underline;
      }
    `,
);

export const LargeButton = styled.button(
  ({ theme }: Theme) => `
      height: 96px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      appearance: none;
      border: 0;
      cursor: pointer;
      background-image: ${theme.linearGradient.blueGreenLeft};
      transition: transform  0.2s;
      backface-visibility: hidden; 

      ${theme.mobileView} {
        height: 72px;
      }

      svg {
        margin-right: 24px;
      }

      span {
        text-transform: uppercase;
        font-size: 40px;
        font-weight: 900;

        ${theme.mobileView} {
          font-size: 24px;
        }
      }

      &:hover {
        transform: scale(1.03);
      }
  `,
);

export const PersonalDetailsFooter = styled(Form.Footer)(
  ({ theme }: Theme) => `
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    ${Input.Group} {
      flex: 1
    }

    ${theme.mobileView} {
      ${Input.Group} {
        flex: 1 1 100%;
        margin-bottom: 48px;
      }

      ${Input.Button} {
        display: inline-block;
        margin: auto;
      }
    }
`,
);
