import React, { useEffect, useState } from 'react';
import { LargeButton, NavigationButton, Bubble, BubblesContainer } from './styles';
import Styles from '../styles';
import Spotify from '../../../agents/Spotify';
import { SpotifyIcon } from '../../../icons';
import { useWindowWidth } from '../../../utils/isMobile';
import SpotfiyArtist1 from '../../../assets/images/spotify-artist-1.png';
import SpotfiyArtist2 from '../../../assets/images/spotify-artist-2.png';
import SpotfiyArtist3 from '../../../assets/images/spotify-artist-3.png';
import SpotfiyArtist4 from '../../../assets/images/spotify-artist-4.png';
import SpotfiyArtist5 from '../../../assets/images/spotify-artist-5.png';
import SpotfiyArtist6 from '../../../assets/images/spotify-artist-6.png';
import API from '../../../agents/API';

import { get as getLocalStorage } from '../../../utils/localStorage';
import { set as setSessionStorage } from '../../../utils/sessionStorage';

const { Header, Footer, Title, Subtitle } = Styles;

export const ConnectSpotify: React.FunctionComponent<any> = ({ nextStep, prevStep, setState, getState }) => {
  const updateContact = async ({ data }) => {
    const token = getLocalStorage('token');

    const { data: lineup } = await API.getLineup();

    const { data: suggested } = await Spotify.suggestArtists({
      lineup_artists: lineup && lineup.length ? lineup : [],
      user_id: data.user.id,
    }).catch((e) => e);

    const { data: updatedContact } = await API.updateContact({
      token,
      contact: {
        spotify_id: data.user.id,
        artists: suggested && suggested.length ? suggested.map(({ wordpress_id }) => wordpress_id) : [],
      },
    });

    if (updatedContact) {
      setSessionStorage('user', JSON.stringify(updatedContact));
      setState({ ...getState(), ...updatedContact });
      nextStep();
    }
  };

  const [url, setURL] = useState(null);

  useEffect(() => {
    const fetchURL = async () => {
      const result = await Spotify.connectURL({ origin: window.location.origin });
      setURL(result.data);
    };

    fetchURL();
  }, []);

  const isMobile = useWindowWidth() <= 530;

  return (
    <>
      <NavigationButton onClick={() => prevStep()} align="left">
        Back
      </NavigationButton>

      <Header>
        <Title>Connect your Spotify</Title>
        <Subtitle>Share your favourite artists with us and start building out your own Splendour schedule.</Subtitle>
      </Header>

      <BubblesContainer>
        <Bubble
          gradient="blueGreenTop"
          image={SpotfiyArtist4}
          size="lg"
          style={isMobile ? { left: 100, top: 26 } : { left: 168, top: 26 }}
        />
        <Bubble gradient="greenPinkTop" image={SpotfiyArtist5} size="md" style={isMobile ? { left: 0, top: 168 } : { left: 0, top: 98 }} />
        <Bubble
          size="md"
          image={SpotfiyArtist1}
          gradient="greenPinkTop"
          style={isMobile ? { left: 220, bottom: 0 } : { left: 288, bottom: 0 }}
        />
        <Bubble gradient="greenPinkTop" image={SpotfiyArtist6} size="sm" style={isMobile ? { left: 32, top: 12 } : { left: 96, top: 0 }} />
        <Bubble gradient="greenPinkTop" image={SpotfiyArtist3} size="sm" style={isMobile ? { left: 300, top: 0 } : { left: 360, top: 0 }} />
        <Bubble
          gradient="greenPinkTop"
          image={SpotfiyArtist2}
          size="sm"
          style={isMobile ? { left: 300, top: 146 } : { left: 408, top: 146 }}
        />
      </BubblesContainer>

      <LargeButton
        onClick={() => {
          if (url) {
            // Open the popup
            window.open(url);
            // Wait to recieve a message and update the contact/state
            window.addEventListener('message', updateContact, false);
          }
        }}
      >
        <SpotifyIcon size="lg" />
        <span>Connect Now</span>
      </LargeButton>

      <Footer>
        <NavigationButton align="right" onClick={() => nextStep()}>
          Skip this step
        </NavigationButton>
      </Footer>
    </>
  );
};
