import React from 'react';
import SplatterImage from '../../../assets/images/paint-splatter-white-7.png';
import StyledGreeble from './style';
import { WidthHeightGreebleType } from './types';

const WhiteSplatter7: React.StatelessComponent<WidthHeightGreebleType> = ({ top, bottom, left, right, flipX, flipY }) => (
  <StyledGreeble
    top={top}
    right={right}
    left={left}
    flipY={flipY}
    flipX={flipX}
    bottom={bottom}
    width={15}
    height={15}
    image={SplatterImage}
  />
);

export default WhiteSplatter7;
