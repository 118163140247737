import React from 'react';
import SplatterImage from '../../../assets/images/paint-splatter-pink-4.png';
import StyledGreeble from './style';
import { WidthHeightGreebleType } from './types';

const PinkSplatter4: React.StatelessComponent<WidthHeightGreebleType> = ({ top, bottom, left, right, flipX = false, flipY = false }) => (
  <StyledGreeble
    top={top}
    bottom={bottom}
    left={left}
    right={right}
    flipX={flipX}
    flipY={flipY}
    width={40}
    height={40}
    image={SplatterImage}
  />
);

export default PinkSplatter4;
