import React from 'react';
import styled from 'styled-components';
import MinimalLayout from '../layout/Minimal';
import MasterForm from '../components/MasterForm';
import SubscribeJourney from '../components/MasterForm/Subscribe';
import BlueSplatter from '../components/CSSParallax/Greeblies/BlueSplatter4';
import GreenSplatter from '../components/CSSParallax/Greeblies/GreenSplatter6';
import PinkSplatter from '../components/CSSParallax/Greeblies/PinkSplatter4';
import WhiteSplatter from '../components/CSSParallax/Greeblies/WhiteSplatter7';
import Background from '../assets/images/stars-white.jpg';

const Page = styled.div`
  display: flex;
  padding: 64px 24px;
  min-height: 100vh;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.4);
`;

const Container = styled.div`
  margin: auto;
  max-width: 1248px;
  width: 100%;
  position: relative;
`;

const BackgroundStyles = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  background-image: url(${Background});
  background-position: center;
  background-size: cover;
`;

export const SubscribePage: React.StatelessComponent<any> = () => (
  <MinimalLayout style={{ position: 'relative' }}>
    <BackgroundStyles>
      <PinkSplatter bottom={-15} right={-10} />
      <GreenSplatter />
      <BlueSplatter top={5} left={-12} />
      <WhiteSplatter top={-8} left={15} />
    </BackgroundStyles>

    <Page>
      <Container>
        <MasterForm steps={SubscribeJourney} />
      </Container>
    </Page>
  </MinimalLayout>
);

export default SubscribePage;
