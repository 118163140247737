import axios from 'axios';
import { LineupInterface } from '../types/Agents';

const request = axios.create({
  baseURL: `${process.env.GATSBY_SPOTIFY_CONNECT_ENDPOINT}/spotify`,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

export default {
  connectURL: async (data: { origin: string }) => request.get(`/connect?origin=${data.origin}`),
  suggestArtists: async (data: { user_id: string; lineup_artists: LineupInterface[] }) => request.post('/suggest-artists', data),
};
