import styled from 'styled-components';
import { ThemeType } from '../../types';
import tick from '../../assets/svg/tick-box-inverse.svg';

const Header = styled.div(
  () => `
    text-align: center;
    max-width: 768px;
    margin: auto;
  `,
);

const Footer = styled.div(
  () => `
    padding-top: 64px;
  `,
);

const Title = styled.h2(
  ({ theme }) => `
    color: ${theme.colors.green};
    font-size: 48px;
    font-weight: 900;
    line-height: 1;
    margin-bottom: 24px;
    text-transform: uppercase;
  `,
);

const Subtitle = styled.h5(
  () => `
    font-size: 24px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.4px;
    margin-bottom: 40px;
  `,
);

const Group = styled.div(
  () => `
    position: relative;

    *:not(:last-of-type) {
      margin-bottom: 24px;
    }
`,
);

const Text = styled.input.attrs({ type: 'text' })(
  ({ theme, error, value }: ThemeType & { error?: any; value: any }) => `
    border: 1px solid ${error ? `${theme.colors.pink} !important` : theme.colors.white} ;
    height: 96px;
    width: 100%;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0.6px;
    padding: 0 48px;
    border-radius: 0;
    background-color: rgba(0, 0, 0, .4);

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
      -webkit-box-shadow: 0 0 0px 1000px ${theme.colors.black} inset;
      -webkit-text-fill-color: ${theme.colors.white}; 
      -webkit-transition-delay: 99999s;
    }

    &:not(:focus){
      border-color: ${value ? theme.colors.white : 'rgba(255, 255, 255, .25)'};
      color: ${value ? theme.colors.white : 'rgba(255, 255, 255, .40)'};

      &:hover {
        border-color: ${value ? theme.colors.white : 'rgba(255, 255, 255, .75)'};
      }
    }

    &:focus {
      &, &::placeholder {
        color: ${theme.colors.white};
      }
    }
  `,
);

const Checkbox = styled.input.attrs({ type: 'checkbox' })(
  ({ theme, error }: ThemeType & { error?: string }) => `
    border-radius: 0;
    appearance: none;
    background: transparent;
    height: 16px;
    width: 16px;
    margin: 0;
    position: absolute;    

    & + label {
      line-height: 1.2;
      padding-left: 32px;
      padding-right: 16px;
      cursor: pointer;
      display: block;
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      cursor: pointer;
      height: 16px;
      width: 16px;
    }

    &::after {
      background-size: 12px 12px;
      background-repeat: no-repeat;
      background-position: 3px 3px;
      background-image: url('${tick}');
      opacity: 0;
    }

    &::before {
      transition: border-color .2s ease-in-out;
      border: 1px solid ${error ? theme.colors.pink : theme.colors.mediumGrey};
    }

    &:checked::after {
      opacity: 1;
    }
  `,
);

const Button = styled.button(
  ({ theme }) => `
    font-size: 32px;
    font-weight: 900;
    appearance: none;
    text-transform: uppercase;
    padding: 0;
    background: transparent;
    border: 0;
    color: ${theme.colors.green}
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  `,
);

const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.2px;
`;

const Form: any = styled.form(
  ({ loading }: { loading: boolean }) => `   
    ${Group},
    ${Button} {
      ${loading ? 'opacity: .75; pointer-events: none;' : ''}
    }
  `,
);

export default { Header, Footer, Title, Subtitle, ...Form };
export const Input = { Group, Label, Text, Checkbox, Button };
