import React, { useState } from 'react';
import { get as getSessionStorage } from '../../../utils/sessionStorage';
import { Link } from '../../../styles/components';
import { LinkWrapper, Label } from '../../../blocks/Spotify/styles';
import { PlaylistContainer } from './styles';
import { SpotifyIcon } from '../../../icons';
import Floater from '../../../components/Floater';

const SpotifyButton = ({ cta }) => (
  <LinkWrapper>
    <Link href={cta.link.url} target={cta.link.target}>
      <SpotifyIcon />
      <Label className="h7">{cta.text}</Label>
    </Link>
  </LinkWrapper>
);

const SpotifyEmbed = ({ url, onLoad }) => (
  <Floater>
    <iframe onLoad={onLoad} src={url} width="352" height="432" frameBorder="0" allow="encrypted-media" />
  </Floater>
);

export const OfficialPlaylist: React.FunctionComponent<any> = () => {
  const [visible, setVisibility] = useState(false);

  const origin = getSessionStorage('origin');

  return (
    <PlaylistContainer>
      <div>
        <h2 className="h0 text-green">Thanks for signing up</h2>
        <h5 className="h5 text-white text-unset">Check your email now for more info on your My Splendour schedule.</h5>
        <h3 className="h4 text-green">Follow the Official Splendour in the Grass playlist</h3>
        <SpotifyButton
          cta={{
            text: 'Save to my Spotify',
            link: { url: 'https://open.spotify.com/playlist/1Ql0aH7dGm2apvFOgS4UI0?si=TENlgD1gSlSMY58szh6QNQ', target: '_blank' },
          }}
        />
        {origin && <>
          <br /><br />
          <Link href={origin} >
            <Label className="h7">Back to site</Label>
          </Link>
        </>}
      </div>
      <div className={`spotify-embed ${visible ? 'visible' : ''}`}>
        <SpotifyEmbed url="https://open.spotify.com/embed/playlist/1Ql0aH7dGm2apvFOgS4UI0" onLoad={() => setVisibility(true)} />
      </div>
    </PlaylistContainer>
  );
};
